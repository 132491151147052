import React from 'react';

import thumbnail01 from '../../assets/img/portfolio/01-thumbnail.jpg'


export default class Tipologia extends React.Component{
    render(){
        return(

            <div className="col-md-4 col-sm-6 portfolio-item">
               <a href="#portfolioModal1" className="portfolio-link" data-toggle="modal">
                    <div className="portfolio-hover">
                        <div className="portfolio-hover-content">
                            <i className="fa fa-plus fa-3x"></i>
                        </div>
                    </div>
                    <img src={thumbnail01} className="img-fluid" alt=""/>
                </a>
                <div className="portfolio-caption">
                    <h4>Threads</h4>
                    <p className="text-muted">Illustration</p>
                </div>
        </div>
        )
    }
}
