

import React from 'react'

import Header from "./Sections/Header"
import Navigation from "./Sections/Navigation"
import About from "./Sections/About"
import Services from "./Sections/Services"
import Portfolio from "./Sections/Portfolio"
import Clients from './Sections/Clients'
import Contact from './Sections/Contact'
import Footer from './Sections/Footer'

class LandingPage extends React.Component {
  
  render(){
    return (
      <div className="LandingPage">
        <Navigation/>
        <Header/>
        <Services/>
        <Portfolio/>
        <About/>
        <Clients/>
        <Contact/>

        <div id="location" class="map">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3121.3292920680888!2d-8.9021237852329!3d38.52617687634588!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd1943a05458b97f%3A0xca5e91f0c9104351!2sR.%20Ant%C3%B3nio%20Jos%C3%A9%20Marques%209B%2C%202900-234%20Set%C3%BAbal!5e0!3m2!1spt-PT!2spt!4v1569419979645!5m2!1spt-PT!2spt" width="100%" height="100%" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
          <br />
          <small>
            <a href="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3121.3292920680888!2d-8.9021237852329!3d38.52617687634588!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd1943a05458b97f%3A0xca5e91f0c9104351!2sR.%20Ant%C3%B3nio%20Jos%C3%A9%20Marques%209B%2C%202900-234%20Set%C3%BAbal!5e0!3m2!1spt-PT!2spt!4v1569419979645!5m2!1spt-PT!2spt"></a>
          </small>
        </div>
        
        <Footer/>
      </div>
    )
  }
}

export default LandingPage