import React from 'react'

import thumbnail01 from '../../../assets/img/about/1.jpg'
import thumbnail02 from '../../../assets/img/about/2.jpg'
import thumbnail03 from '../../../assets/img/about/3.jpg'
import thumbnail04 from '../../../assets/img/about/4.jpg'

function About () {
        return (
            <section id="about">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 text-center">
                            <h2 className="section-heading">Sobre</h2>
                            <h3 className="section-subheading text-muted">Há mais de 20 anos a garantir a segurança dos nossos clientes!</h3>
                        </div>
                        
                        <div className="col-lg-12" style={{paddingBottom: "100px"}}>
                            Somos uma empresa com espirito jovem e dinâmica. Dedicamos a nossa actividade na área da 
                            manutenção de extintores e comercialização de material contra incêndio, tais como: 
                            <ul>
                                <li>Caixas de S.I.</li>
                                <li>Carretéis</li>
                                <li>Mangueiras</li>
                                <li>Marcos de Incêndio</li>
                                <li>Sinalética</li>
                                <li>Equipamentos de Prevenção e Combate a incêndios</li>
                                <li>Segurança Individual e Industrial</li>
                            </ul>
                                                        
                            <p>A Securfire está vocacionada para um atendimento personalizado, 
                                rápido e eficaz tanto na manutenção, como na entrega dos seus produtos. 
                                Certificada pela <b>NP 4413:2012</b> e acreditada pela <b>ANPC com número de registo Nº377</b>.</p>

                            <p>O nosso lema consiste em ter uma grande variedade de produtos certificados, 
                                    com garantia de qualidade e assegurar a execução dos serviços por técnicos certificados.</p>
                        </div>                                       

                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <ul className="timeline">
                                <li>
                                    <div className="timeline-image">
                                        <img className="rounded-circle img-fluid" src={thumbnail01} alt=""/>
                                    </div>
                                    <div className="timeline-panel">
                                        <div className="timeline-heading">
                                            <h4>2009-2011</h4>
                                            <h4 className="subheading">Our Humble Beginnings</h4>
                                        </div>
                                        <div className="timeline-body">
                                            <p className="text-muted">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sunt ut voluptatum eius sapiente, totam reiciendis temporibus qui quibusdam, recusandae sit vero unde, sed, incidunt et ea quo dolore laudantium consectetur!</p>
                                        </div>
                                    </div>
                                </li>
                                <li className="timeline-inverted">
                                    <div className="timeline-image">
                                        <img className="rounded-circle img-fluid" src={thumbnail02} alt=""/>
                                    </div>
                                    <div className="timeline-panel">
                                        <div className="timeline-heading">
                                            <h4>March 2011</h4>
                                            <h4 className="subheading">An Agency is Born</h4>
                                        </div>
                                        <div className="timeline-body">
                                            <p className="text-muted">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sunt ut voluptatum eius sapiente, totam reiciendis temporibus qui quibusdam, recusandae sit vero unde, sed, incidunt et ea quo dolore laudantium consectetur!</p>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="timeline-image">
                                        <img className="rounded-circle img-fluid" src={thumbnail03} alt=""/>
                                    </div>
                                    <div className="timeline-panel">
                                        <div className="timeline-heading">
                                            <h4>December 2012</h4>
                                            <h4 className="subheading">Transition to Full Service</h4>
                                        </div>
                                        <div className="timeline-body">
                                            <p className="text-muted">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sunt ut voluptatum eius sapiente, totam reiciendis temporibus qui quibusdam, recusandae sit vero unde, sed, incidunt et ea quo dolore laudantium consectetur!</p>
                                        </div>
                                    </div>
                                </li>
                                <li className="timeline-inverted">
                                    <div className="timeline-image">
                                        <img className="rounded-circle img-fluid" src={thumbnail04} alt=""/>
                                    </div>
                                    <div className="timeline-panel">
                                        <div className="timeline-heading">
                                            <h4>July 2014</h4>
                                            <h4 className="subheading">Phase Two Expansion</h4>
                                        </div>
                                        <div className="timeline-body">
                                            <p className="text-muted">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sunt ut voluptatum eius sapiente, totam reiciendis temporibus qui quibusdam, recusandae sit vero unde, sed, incidunt et ea quo dolore laudantium consectetur!</p>
                                        </div>
                                    </div>
                                </li>
                                <li className="timeline-inverted">
                                    <div className="timeline-image">
                                        <h4>Faça parte
                                            <br/> da 
                                            <br/> história!</h4>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        )
    }


export default About;