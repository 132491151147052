import React from 'react'

import thumbnail01 from "../../../assets/img/logos/clients/1.jpeg" 
import thumbnail02 from "../../../assets/img/logos/clients/2.jpeg" 
import thumbnail03 from "../../../assets/img/logos/clients/3.jpeg" 
import thumbnail04 from "../../../assets/img/logos/clients/4.jpeg" 
import thumbnail05 from "../../../assets/img/logos/clients/5.jpeg" 
class Clients extends React.Component{
    render(){
        return (
    <aside className="clients">
        
        <div className="container">
            <div className="row justify-content-center align-items-center">
                <div className="col-md-2 col-sm-6">
                    <a href="#">
                        <img src={thumbnail01} className="img-fluid d-block mx-auto" alt=""/>
                    </a>
                </div>
                <div className="col-md-2 col-sm-6">
                    <a href="#">
                        <img src={thumbnail02} className="img-fluid d-block mx-auto" alt=""/>
                    </a>
                </div>
                <div className="col-md-2 col-sm-6">
                    <a href="#">
                        <img src={thumbnail03} className="img-fluid d-block mx-auto" alt=""/>
                    </a>
                </div>
                <div className="col-md-2 col-sm-6">
                    <a href="#">
                        <img src={thumbnail04} className="img-fluid d-block mx-auto" alt=""/>
                    </a>
                </div>
                <div className="col-md-2 col-sm-6">
                    <a href="#">
                        <img src={thumbnail05} className="img-fluid d-block mx-auto" alt=""/>
                    </a>
                </div>
            </div>
        </div>
    </aside>
        )
    }
}

export default Clients;