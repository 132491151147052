import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";
import * as serviceWorker from './serviceWorker';

import './assets/lib/bootstrap/css/bootstrap.css';
import './assets/lib/font-awesome/css/font-awesome.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min';

import './assets/css/agency.css'
import './assets/js/agency'
import './assets/js/jqBootstrapValidation'
import './assets/js/contact_me'

// pages for this product
import LandingPage from './views/LandingPage/LandingPage.js';


var hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Route path="/" component={LandingPage} />
    </Switch>
  </Router>,  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();