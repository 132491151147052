import React from "react"

import banner from '../../../assets/img/map-image.png'

function Header() {
    return (
        
        <header>
        <div className="container">
        <img source={banner} style={{paddingTop: "100px"}} className="img-fluid" alt=''/>
            <div className="intro-text">
                <div className="intro-heading">Secur<span style = {{color: "#f9554c"}}>fire</span></div>
                <div className="intro-lead-in">Comércio e manutenção de equipamentos contra incêndio</div>
                <a href="#services" className="page-scroll btn btn-xl">Mais informações</a>
            </div>
        </div>
    </header>
    )
}

export default Header;