import React from 'react';
import { Link, animateScroll as scroll } from "react-scroll";
import './Navigation.css';
import logoRed from '../../../assets/img/logos/logo-red.jpg'
import logoGray from '../../../assets/img/logos/logo-gray.jpg'
import logo from '../../../assets/img/logos/original-logo.png'

class Navigation extends React.Component {
    render() {
        return (
            <nav className="navbar fixed-top navbar-toggleable-md navbar-inverse navbar-shrink" id="mainNav">
                <div className="container">
                    <button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                        Menu <i className="fa fa-bars"></i>
                    </button>

                    <img src={logo} className="img-fluid" style = {{height: "35px"}} alt=""/>

                    <a className="navbar-brand page-scroll" href="#page-top"><span style = {{  color: "whitesmoke", paddingLeft: "20px"}}>Secur</span>fire</a>
                    <span style = {{color: "whitesmoke", width: "inherit"}}><b>Email: </b>geral@securfire.pt <b>Tel.: </b>265571965</span>
                    <div className="collapse navbar-collapse" id="navbarResponsive">
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item">
                                <Link style={{cursor: "pointer"}} className="nav-link page-scroll btn" activeClass="active" to="services" spy={true} smooth={true} offset={0} duration= {500}>
                                    Serviços
                                </Link>     
                            </li>
                            <li className="nav-item">
                                <Link style={{cursor: "pointer"}} className="nav-link page-scroll btn" activeClass="active" to="portfolio" spy={true} smooth={true} offset={0} duration= {500}>
                                    Portfólio
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link style={{cursor: "pointer"}} className="nav-link page-scroll btn" activeClass="active" to="about" spy={true} smooth={true} offset={0} duration= {500}>
                                    Sobre
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link style={{cursor: "pointer"}} className="nav-link page-scroll btn" activeClass="active" to="team" spy={true} smooth={true} offset={0} duration= {500}>
                                    Equipa
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link style={{cursor: "pointer"}} className="nav-link page-scroll btn" activeClass="active" to="contact" spy={true} smooth={true} offset={0} duration= {500}>
                                    Contactos
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
                
            </nav>
        )
    }
}

export default Navigation;