import React from "react"


import thumbnail01 from "../../../assets/img/portfolio/01-thumbnail.jpg"
import thumbnail02 from "../../../assets/img/portfolio/02-thumbnail.jpg"
import thumbnail03 from "../../../assets/img/portfolio/03-thumbnail.jpg"
import thumbnail04 from "../../../assets/img/portfolio/04-thumbnail.jpg" 
import thumbnail05 from "../../../assets/img/portfolio/05-thumbnail.jpg" 
import thumbnail06 from "../../../assets/img/portfolio/06-thumbnail.jpg" 

import Tipologia from '../../components/Tipologia'
import TipologiaWindow from '../../components/TipologiaWindow'

function Portfolio() {
    return (
        <div>
        <section id="portfolio" className="bg-faded">
        <div className="container">
            <div className="row">
                <div className="col-lg-12 text-center">
                    <h2 className="section-heading">Portfolio</h2>
                    <h3 className="section-subheading text-muted">Lorem ipsum dolor sit amet consectetur.</h3>
                </div>
            </div>

            <div className="row">
            <Tipologia/>
           
            </div>
        </div>
    </section>

    <TipologiaWindow/>
    
    </div>
    )
}




export default Portfolio; 